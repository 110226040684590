.footer-box-a, .footer-box-b, .footer-box-c, .footer-box-d{
    margin-bottom:20px;
}

.footer-box{
	background:#202020;
}
.footer-box .social-circle-border{
	background-color: transparent;
	color: #fff;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.footer-box-a h3{
	font-size:24px;
    font-family: 'nautilus_pompiliusregular';
}
.footer-box-a p{
	font-size: 14px;
	font-family: 'Roboto', sans-serif;
	color:#ffffff;
	line-height: 25px;
}


.footer-box-b h3{
	font-size:24px;
    font-family: 'nautilus_pompiliusregular';
}
.footer-box-b p{
	font-size: 14px;
	font-family: 'Roboto', sans-serif;
	color:#ffffff;
}
.footer-box-b ul li{
	line-height:30px;
}
.footer-box-b ul li a{
	color:#ffffff;
}

.footer-box-b ul li a:hover{
	padding-left:5px;
}	

.footer-box-c h3{
	font-size:24px;
    font-family: 'nautilus_pompiliusregular';
}
.footer-box-c p{
	font-size: 14px;
	font-family: 'Roboto', sans-serif;
	color:#ffffff;
}


.footer-box-c p i{
	font-size: 20px;
    position: relative;
    top: 4px;
    float: left;
    min-width: 20px;
    text-align: center;
}

.footer-box-c p span{
	display: table;
    padding-left: 12px;
	padding-top:1px;
}

.footer-box-c p a{
	color:#ffffff;
}



.footer-box-d h3{
	font-size:24px;
    font-family: 'nautilus_pompiliusregular';
}
.footer-box-d p{
	font-size: 14px;
	font-family: 'Roboto', sans-serif;
	color:#ffffff;
}

.footer-box-d ul{
	margin:0px;
	padding:0px;
	list-style:none;
}
.footer-box-d ul li{
	display: inline-block;
    width: 100%;
	padding-bottom:12px;
}
.footer-box-d ul li p{
	padding:0px;
	margin:0px;
}
.footer-box-d ul li span{
	color: #666;
}


