.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black; /* Background color of the preloader */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Set a high z-index to overlay other content */
  }
  
  .loader {
    position: relative;
    width: 100px; /* Adjust the size of your preloader */
    height: 50px; /* Adjust the size of your preloader */
  }
  
  .left-to-right,
  .right-to-left {
    position: absolute;
    width: 100px; /* Adjust the size of your moving images */
    height: 100px; /* Adjust the size of your moving images */
    background-image: url('../images/logo1\ \(1\).jpeg'); /* Add your image paths */
    background-size: cover;
    animation: moveLR 1s linear infinite alternate; /* Adjust the animation duration as needed */
  }
  
  .right-to-left {
    background-image: url('../images/logo2.jpeg'); /* Add your image path */
    animation-direction: alternate-reverse;
  }
  
  @keyframes moveLR {
    0% {
      left: -150%; /* Start from a position to the left of the screen */
    }
    100% {
      left: 150%;
    }
  }
  