/* scrollAnimation.css */

.scroll-animation {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.8s ease, transform 0.8s ease;
  }
  
  .scroll-animation.fade-in {
    opacity: 1;
    transform: translateY(0);
  }
  

