body {
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
}

/* Custom styles for the scrollbar */
body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: orangered; /* Change the color of the scrollbar thumb */
  border-radius: 5px;
}

body::-webkit-scrollbar-track {
  background-color: #000; /* Change the color of the scrollbar track */
}

/* Example of an animation for the thumb */
body::-webkit-scrollbar-thumb:hover {
  background-color: darkorange; /* Change the color on hover */
  transition: background-color 0.3s ease-in-out;
}

.App {
  text-align: center;
  padding: 10px; /* Add some padding */
}

.App-logo {
  height: 40vmin; /* Use viewport height for responsiveness */
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin); /* Use viewport width for responsiveness */
  color: white;
  padding: 20px; /* Add some padding */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Media Queries for Responsive Design */

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) {
  body {
    overflow-x: hidden;
    font-family: 'Roboto', sans-serif;
  }

}

/* Medium devices (landscape tablets and desktops, 768px and up) */
@media (min-width: 768px) {
  

  body {
    overflow-x: hidden;
    font-family: 'Roboto', sans-serif;
  }

}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  
  body {
    overflow-x: hidden;
    font-family: 'Roboto', sans-serif;
  }

}

/* Extra large devices (4K screens and up) */
@media (min-width: 2560px) {
 
  body {
    overflow-x: hidden;
    font-family: 'Roboto', sans-serif;
  }

}
